<template>
  <div class="payment-hub">
    <!-- Tabs Section -->
    <b-tabs
      card
      no-fade
      :class="tabsDisabled ? 'disablePointerEvents' : ''"
      lazy
      v-model="tabIndex"
    >
      <b-tab
        v-for="(tab, index) in enabledTabs"
        :key="index"
        :title="tab.title"
        @click="selectPaymentType(tab.id)"
        :id="tab.id"
      >
        <component class="content" :is="tab.content" @changeTab="(e) => changeTab(e)" />
      </b-tab>
    </b-tabs>
    <!-- End Tabs Section -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PaymentHubTable from "./components/PaymentHubTable.vue";
import HistorialPayment from "./components/HistorialPaymentIndex.vue";

export default {
  name: "paymentHub",
  components: {
    PaymentHubTable,
    HistorialPayment,
  },
  data() {
    return {
      /* Variables Tabs */
      selectedTabId: "pending-invoices",
      tabIndex: 0,
      tabsDisabled: false,
      paymentTabs: [
        {
          title: "PENDING INVOICES",
          id: "pending-invoices",
          enabled: true,
          activeTab: false,
          content: PaymentHubTable,
        },
        {
          title: "PAYMENT HISTORY",
          id: "payment-history",
          enabled: true,
          activeTab: true,
          content: HistorialPayment,
        },
      ],
    };
  },
  computed: {
    enabledTabs() {
      return this.paymentTabs.filter((tab) => tab.enabled === true);
    },
    ...mapGetters({
      changeSelectedTab: "paymentHub/getChangeTabSelected",
    }),
  },
  watch: {
    changeSelectedTab() {
      if (this.changeSelectedTab) {
        this.changeTab("payment-history");
        return;
      }
      this.changeTab("pending-invoices");
    }
  },
  methods: {
    /* Tabs Methods Section */
    selectPaymentType(tabId) {
      this.selectedTabId = tabId;
    },

    changeTab(tabId) {
      const index = this.paymentTabs.findIndex((item) => item.id === tabId);
      if (index !== -1) this.tabIndex = index;
    },

  },
};
</script>
<style lang="scss" scoped>
.payment-hub {
  padding: 1.5rem 15px;
}

.content {
  height: auto;
  width: 100%;
}
///****Tabs styles****\\\\
.disablePointerEvents {
  pointer-events: none;
}
/* Refactorizar luego, crear un extend con estos estilos y la logica para hacer un componente tab reutilizable */
::v-deep .card-header {
  background-color: $color-white;
  border-bottom: 0px;
  padding: 12px 9.8px;
}
::v-deep .card-body {
  background-color: white;
  border: 1px solid $color-border-container;
  border-radius: 0px 10px 10px 10px;
  font-family: $font-family-portal;
  padding: 2%;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 0px;
  }
}
::v-deep .nav-tabs {
  &::-webkit-scrollbar {
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-gray-dark;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-light;
    border: 2.1px solid $color-gray-dark;
    border-radius: 10px;
  }
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
::v-deep .nav-tabs .nav-link {
  background-color: $color-gray-dark;
  color: $color-white;
  font-family: $font-family-portal;
  font-weight: bold;
  @include font-small-information;
  border: 1px solid $color-border-container;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px 15px;
}
::v-deep .nav-item a {
  font-weight: bold !important;
}
::v-deep .nav-tabs .nav-link {
  min-width: 110px;
}
::v-deep .nav-tabs .nav-link.active {
  color: $color-primary-company;
  font-family: $font-family-portal;
  font-weight: bold;
  @include font-small-information;
  background-color: $color-gray-light;
  border: 1px solid $color-border-container;
  border-bottom: 1px solid $color-gray-light;
  padding: 15px 11px;
}
::v-deep .nav-tabs > li {
  &:first-child > a {
    border-top-left-radius: 20px;
  }
  &:last-child > a {
    border-top-right-radius: 20px;
  }
}
</style>
